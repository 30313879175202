import React, { useEffect, useState } from 'react'
import { get_profile_history, get_profile_info_profile_id, get_profile_stats, get_user_client_challenges } from '../../../../Servicies/ProfileService'
import { color_2, color_3 } from '../../../../Styles/Colors'
import { useParams } from 'react-router-dom'
import ThreeDotsLoader from '../../../LoaderPages/ThreeDotsLoader'
import { dark_blue_2 } from '../../../../Styles/Colors'
import { get } from '../../../../LocalStorage/LocalStorage'

const PointsHistoryTable = ({ statsData }) => {
  const { profile_id } = useParams()
  const [stats, setStats] = useState(statsData || null)
  const [collection, setCollection] = useState([])
  const [loader, setLoader] = useState(false)
  useEffect(() => {
    if (get('token')) {
      get_points_history()
    }
  }, [])

  const get_points_history = () => {
    get_profile_history().then(result => {
      if (result.success) {
        setCollection(result.history)
      }
    })
  }
  function formatTimestamp(timestampStr) {
    const timestamp = new Date(timestampStr);
    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    const formattedDate = timestamp.toLocaleDateString('en-US', options);
    return formattedDate;
  }

  return (
    <div>
      {loader ?
        <div className='w3-center'>
          <ThreeDotsLoader center={true} size={30} />
        </div>
        :
        <>
          <div className='w3-row'>
            <div className='w3-row w3-padding-small ' style={{ maxHeight: '78vh', overflow: 'auto' }}>
              <h2 className='w3-center no-margin w3-padding' style={{ backgroundColor: 'white', color: dark_blue_2, borderRadius: '8px 8px 0px 0px' }}>Challenge History</h2>
              <table id='data-table' className='w3-table-all w3-hoverable dashboard_table'>
                <thead>
                  <tr style={{ backgroundColor: "var(--dark-blue-2)", color: "white" }}>
                    <th className='w3-padding w3-center'>Challenge</th>
                    <th className='w3-padding w3-center'>XP Added</th>
                    <th className='w3-padding w3-center w3-hide-small'>XP Removed</th>
                    <th className='w3-padding w3-center'>XP Final</th>
                    <th className='w3-padding w3-center w3-hide-small'>Created On</th>
                  </tr>
                </thead>
                <tbody>
                  <>
                    {
                      collection.length !== 0 &&
                      collection.map((item, index) => (
                        <tr key={index} style={{ cursor: "pointer" }}>
                          <td className='w3-center'>
                            {item.description}
                          </td>
                          <td className='w3-center'>
                            {item.xp_added}
                          </td>
                          <td className='w3-center w3-hide-small'>
                            {item.xp_removed}
                          </td>
                          <td className='w3-center'>
                            {item.xp_final}
                          </td>
                          <td className='w3-center w3-hide-small'>
                            {formatTimestamp(item.created_at)}
                          </td>
                        </tr>
                      ))
                    }
                  </>
                </tbody>
              </table>
            </div>
          </div>
        </>
      }
    </div>
  )
}

export default PointsHistoryTable
