var conn = {}
export function initializeLocalStorage() {
  conn = JSON.parse(localStorage.getItem('gret'))
  console.log(localStorage.getItem('gret'))
  if (conn === null) {
    conn = {}
    localStorage.setItem('gret', JSON.stringify(conn));
  }
  // console.log(conn)
}

export function get(key) {
  if (conn[key] === undefined || conn[key] === 'undefined') {
    return null;
  }
  else if (conn[key] === null || conn[key] === 'null') {
    return null;
  }
  else {
    return conn[key];
  }
}

export function set(key, value) {
  conn[key] = value;
  localStorage.setItem('gret', JSON.stringify(conn));
}

export function deletekey(key) {
  if (key in conn) {
    delete conn[key];
    localStorage.setItem('gret', JSON.stringify(conn));
  }
}

export function is_available() {
  try {
    localStorage.setItem('gret', 'gret');
    localStorage.removeItem('gret');
    return true;
  } catch (error) { return false; }
}

export function logoutClear() {
  localStorage.removeItem('gret');
  conn={}
}


