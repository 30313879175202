import environment from "../Environments/Environment";
import { get } from "../LocalStorage/LocalStorage";

export const save_experience = async (scene_info) => {
    const token = 'Token ' + get('token')
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Authorization: token },
        body: JSON.stringify(scene_info)
    };
    const response = await fetch(`${environment.server_root}/api/avatar/save_experience/`, requestOptions);
    const data = await response.json();
    data.status_code = response.status
    return data
}

export const update_experience = async (scene_info) => {
    const token = 'Token ' + get('token')
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Authorization: token },
        body: JSON.stringify(scene_info)
    };
    const response = await fetch(`${environment.server_root}/api/avatar/update_experience/`, requestOptions);
    const data = await response.json();
    data.status_code = response.status
    return data
}

export const get_experience = async (experience_id, is_editing = false) => {
    const token = 'Token ' + get('token')
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', Authorization: token },
        params: JSON.stringify(experience_id)
    };
    var is_superuser = get('is_superuser') ? get('is_superuser') : false
    const response = await fetch(`${environment.server_root}/api/avatar/get_experience?experience_id=${experience_id}&is_superuser=${is_superuser}&is_editing=${is_editing}`, requestOptions);
    const data = await response.json();
    data.status_code = response.status
    return data
}

export const get_video_experience = async (experience_id, is_editing = false) => {
    const token = 'Token ' + get('token')
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', Authorization: token },
        params: JSON.stringify(experience_id)
    };
    var is_superuser = get('is_superuser') ? get('is_superuser') : false
    const response = await fetch(`${environment.server_root}/api/avatar/get_video_experience?experience_id=${experience_id}&is_superuser=${is_superuser}&is_editing=${is_editing}`, requestOptions);
    const data = await response.json();
    data.status_code = response.status
    return data
}

export const get_experience_editing = async (experience_id) => {
    const token = 'Token ' + get('token')
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', Authorization: token },
        params: JSON.stringify(experience_id)
    };
    const response = await fetch(`${environment.server_root}/api/avatar/get_experience_editing?experience_id=${experience_id}`, requestOptions);
    const data = await response.json();
    data.status_code = response.status
    return data
}

export const get_edit_access_info = async (experience_id) => {
    const token = 'Token ' + get('token')
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', Authorization: token },
        params: JSON.stringify(experience_id)
    };
    const response = await fetch(`${environment.server_root}/api/avatar/get_edit_access_info/?unique_id=${experience_id}`, requestOptions);
    const data = await response.json();
    data.status_code = response.status
    return data
}

export const get_edit_access_info_vrweb = async (experience_id) => {
    const token = 'Token ' + get('token')
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', Authorization: token },
        params: JSON.stringify(experience_id)
    };
    const response = await fetch(`${environment.server_root}/api/avatar/get_edit_access_info_vrweb/?unique_id=${experience_id}`, requestOptions);
    const data = await response.json();
    data.status_code = response.status
    return data
}

export const edit_access_vrexp = async (email_data) => {
    const token = 'Token ' + get('token')
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Authorization: token },
        body: JSON.stringify(email_data)
    };
    const response = await fetch(`${environment.server_root}/api/avatar/edit_access_vrexp/`, requestOptions);
    const data = await response.json();
    data.status_code = response.status
    return data
}

export const edit_access_vrweb = async (email_data) => {
    const token = 'Token ' + get('token')
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Authorization: token },
        body: JSON.stringify(email_data)
    };
    const response = await fetch(`${environment.server_root}/api/avatar/edit_access_vrweb/`, requestOptions);
    const data = await response.json();
    data.status_code = response.status
    return data
}

export const get_experience_preview = async (experience_id) => {
    const token = 'Token ' + get('token')
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', Authorization: token },
        params: JSON.stringify(experience_id)
    };
    var is_superuser = get('is_superuser') ? get('is_superuser') : false
    const response = await fetch(`${environment.server_root}/api/avatar/get_experience_preview?experience_id=${experience_id}&is_superuser=${is_superuser}`, requestOptions);
    const data = await response.json();
    data.status_code = response.status
    return data
}

export const meeting_alert = async (scene_info) => {
    const token = 'Token ' + get('token')
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Authorization: token },
        body: JSON.stringify(scene_info)
    };
    const response = await fetch(`${environment.server_root}/api/avatar/meeting_alert/`, requestOptions);
    const data = await response.json();
    data.status_code = response.status
    return data
}

