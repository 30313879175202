import environment from '../Environments/Environment'
import { get, set } from '../LocalStorage/LocalStorage';

export const profile_visits = async () => {
    if (!get('is_superuser')) {
        const token = 'Token ' + get('token')
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', Authorization: token },
            body: {}
        };
        const response = await fetch(`${environment.server_root}/api/profile/profile_visits/`, requestOptions);
        const data = await response.json();
        set('session_id', data.session_id)
        set('start_session', data.start_session)
        data.status_code = response.status
        return data
    }
}

export const update_profile_visits = async (update_info) => {
    if (!get('is_superuser')) {
        const token = 'Token ' + get('token')
        update_info['session_id'] = get('session_id')
        if (update_info.end_session) {
            set('session_id', null)
            set('start_session', null)
        }
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', Authorization: token },
            body: JSON.stringify(update_info)
        };
        const response = await fetch(`${environment.server_root}/api/profile/update_profile_visits/`, requestOptions);
        const data = await response.json();
        data.status_code = response.status
        return data
    }
}